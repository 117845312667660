import Rails from '@rails/ujs'

Rails.start()

import { Turbo } from '@hotwired/turbo-rails'
import { FetchRequest } from '@rails/request.js'
import { qs } from 'qs'
import 'chartkick/chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import * as CollateralDesign from 'collateral_design'
import Moment from 'moment'
import Inputmask from 'inputmask'
import * as pdfJsLib from 'pdfjs-dist'

// Options
pdfJsLib.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.js'

// External dependencies
require('@rails/actiontext')
require('@rails/activestorage').start()
require('alpine-magic-helpers')
require('alpine-turbo-drive-adapter')
require('alpinejs')
require('flatpickr')
require('trix')
require('prismjs')

// Internal dependencies
require('./src/advancedSearcheableApp')
require('./src/anchorMenuApp')
require('./src/anchorUrlApp')
require('./src/appraisalCheckingApp')
require('./src/assignVendorsApp')
require('./src/autoAssignmentSettings')
require('./src/batchActionsApp')
require('./src/bidsApp')
require('./src/changeTermsApp')
require('./src/commonResponsesApp')
require('./src/coverageAreaRadiusApp')
require('./src/coverageAreaStateApp')
require('./src/clientAutoAssignmentApp')
require('./src/customSelect')
require('./src/customTrixTools')
require('./src/draggableApp')
require('./src/durationSelectApp')
require('./src/eventConditionsApp')
require('./src/fileFieldApp')
require('./src/flashMessage')
require('./src/mapViewApp')
require('./src/mergeFieldsApp')
require('./src/modal')
require('./src/nestedDynamicForm')
require('./src/newInvoiceApp')
require('./src/newNoteApp')
require('./src/newPaymentApp')
require('./src/notificationsApp')
require('./src/orderInteractionsApp')
require('./src/orderTableColumnsApp')
require('./src/orderWizardPropertyApp')
require('./src/pdfViewerApp')
require('./src/placeInput')
require('./src/phonesInputApp')
require('./src/popoutApp')
require('./src/radiusMapViewApp')
require('./src/rangeDatepickerApp')
require('./src/resizableTableSettings')
require('./src/selectAutocompleteApp')
require('./src/switchButton')
require('./src/tabApp')
require('./src/tableFormApp')
require('./src/tabs')
require('./src/tagsInput')
require('./src/userMenuApp')
require('./src/utils')
require('./src/vendorLicenseApp')
require('./src/vendorProductFeeTableApp')
require('./src/vendorAutoAssignConfigApp')
require('./src/vendorsAutocomplete')
require('./src/vendorsPanelFilter')
require('./src/vendorStatisticsApp')

// Window variables
window.ChartDataLabels = ChartDataLabels
window.ClipboardJS = require('clipboard')
window.CollateralDesign = CollateralDesign
window.confirmDatePlugin = require('flatpickr/dist/plugins/confirmDate/confirmDate')
window.DragSort = require('@yaireo/dragsort')
window.FetchRequest = FetchRequest
window.Inputmask = Inputmask
window.Moment = Moment
window.pdfJsLib = pdfJsLib
window.qs = qs
window.Rails = Rails
window.ResizableTable = require('@validide/resizable-table-columns')
window.Tagify = require('@yaireo/tagify')
window.Turbo = Turbo
window.uuid = require('uuid')

window.localStorageObjectStore = {
  get(id) {
    return JSON.parse(localStorage.getItem(id))
  },

  set(id, data) {
    localStorage.setItem(id, JSON.stringify(data))
  },

  remove(id) {
    localStorage.removeItem(id)
  },
}
