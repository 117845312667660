;(function () {
  const mapViewApp = function (config = {}) {
    return {
      ...config,
      placeMapContainer: null,
      placeMap: null,
      loading: true,
      hasPropertyImage: false,
      displayPropertyImage: false,
      displayStreetView: false,
      displayMap: false,

      init: function ($refs) {
        const __this = this
        if (__this.alpineVersion >= 3) { Alpine.store('mapViewApp', __this) }

        fetch(__this.metadataUrl)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === 'OK' && __this.enabledViews.includes('property_image')) {
              __this.hasPropertyImage = true
              __this.displayPropertyImage = true
            } else {
              __this.hasPropertyImage = false
              __this.displayMap = true
            }

            __this.loading = false
          })

        __this.initPlaceMap($refs)
      },

      initPlaceMap: async function ($refs) {
        const { Map } = await google.maps.importLibrary('maps')
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker')

        this.placeMapContainer = $refs.placeMapContainer

        this.placeMap = new Map(this.placeMapContainer.querySelector('.map-frame'), {
          center: this.mapCenter(),
          zoom: this.zoom,
          mapId: 'place-map',
          mapTypeId: this.maptype,
          streetViewControl: false,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.INLINE_END_BLOCK_END
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.INLINE_END_BLOCK_END
          },
          cameraControl: false
        })

        new AdvancedMarkerElement({
          map: this.placeMap,
          position: this.mapCenter(),
          title: this.fullAddress || this.latLng
        })

        this.placeMapContainer.querySelectorAll('.markers li .marker').forEach((marker) => {
          const title = marker.dataset.title
          const position = {
            lat: parseFloat(marker.dataset.lat),
            lng: parseFloat(marker.dataset.lng)
          }

          new google.maps.marker.AdvancedMarkerElement({
            map: this.placeMap,
            position,
            title,
            content: marker
          })
        })
      },

      mapCenter: function () {
        if (this.latitude && this.longitude) {
          return { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) }
        } else {
          return { q: this.fullAddress }
        }
      },

      toggleTo: function (target) {
        this.loading = true
        this.disableAll()

        switch (target) {
          case 'map':
            this.displayMap = true
            break
          case 'streetView':
            this.displayStreetView = true
            break
          default:
            this.displayPropertyImage = true
        }

        this.loading = false
      },

      disableAll: function () {
        this.displayMap = false
        this.displayStreetView = false
        this.displayPropertyImage = false
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.mapViewApp = mapViewApp
  }

  return mapViewApp
})()
