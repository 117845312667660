(function () {
  const pdfViewerApp = function (pdfViewerId, pdfUrl, options = {}) {
    return {
      pdfUrl,
      pdfViewerId,
      pdfViewer: null,
      pdfDoc: null,
      loading: true,
      scale: options.scale || 1.5,
      page: options.page || 1,
      numPages: 0,

      init: function() {
        this.pdfViewer = document.querySelector(`#${this.pdfViewerId}`)
        this.pdfDoc = pdfjsLib.getDocument(this.pdfUrl)

        this.calculateNumPages()
        this.fitToWidth()
      },

      calculateNumPages: function() {
        const __this = this

        __this.pdfDoc.promise.then(pdf => {
          __this.numPages = pdf.numPages
        })
      },

      fitToWidth: function() {
        const __this = this
        const containerWidth = __this.pdfViewer.clientWidth

        __this.pdfDoc.promise.then(pdf => {
          pdf.getPage(__this.page).then(page => {
            const viewport = page.getViewport({ scale: 1 })
            __this.scale = (Math.round((containerWidth / viewport.width) * 2) / 2) - 0.25
            __this.render()
          })
        })
      },

      toPage: function(page) {
        this.page = parseInt(page) || 1
        this.page = this.page < 1 ? 1 : this.page
        this.page = this.page > this.numPages ? this.numPages : this.page

        this.render()
      },

      zoomIn: function() {
        this.scale = this.scale + 0.25
        this.render()
      },

      zoomOut: function() {
        this.scale = this.scale - 0.25
        this.render()
      },

      render: function() {
        const __this = this

        __this.pdfViewer.innerHTML = ''
        __this.pdfDoc.promise.then(function(pdf) {
          pdf.getPage(__this.page).then(function(page) {
            let viewport = page.getViewport({ scale: __this.scale })

            let canvas = document.createElement('canvas')
            canvas.classList.add('mx-auto', 'shadow-md')
            let context = canvas.getContext('2d')
            canvas.height = viewport.height
            canvas.width = viewport.width

            let renderContext = {
              canvasContext: context,
              viewport: viewport
            }

            page.render(renderContext).promise.then(function() {
              __this.pdfViewer.appendChild(canvas)

              __this.loading = false
            })
          })
        })
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.pdfViewerApp = pdfViewerApp
  }
})();
